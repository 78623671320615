<template>
  <ion-page>
    <!-- Content -->
    <ion-content>
      <!-- Header -->
      <ModalHeader page="post-options" />
      <!-- Content Container -->
      <div class="app-max-width">
        <ion-list>
          <ion-radio-group :value="profileTypes">

            <ion-list-header>
              <ion-label>View posts from</ion-label>
            </ion-list-header>

            <ion-item>
              <ion-label>Everyone</ion-label>
              <ion-radio slot="start" value="everyone" @click="updateProfileTypes('everyone')"></ion-radio>
            </ion-item>

            <ion-item>
              <ion-label>Profiles I follow</ion-label>
              <ion-radio slot="start" value="following" @click="updateProfileTypes('following')"></ion-radio>
            </ion-item>

            <ion-item>
              <ion-label>Artists</ion-label>
              <ion-radio slot="start" value="artists" @click="updateProfileTypes('artists')"></ion-radio>
            </ion-item>

            <ion-item>
              <ion-label>Venues</ion-label>
              <ion-radio slot="start" value="venues" @click="updateProfileTypes('venues')"></ion-radio>
            </ion-item>

            <ion-item>
              <ion-label>Pages</ion-label>
              <ion-radio slot="start" value="pages" @click="updateProfileTypes('pages')"></ion-radio>
            </ion-item>

          </ion-radio-group>
        </ion-list>    
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent, IonList, IonRadioGroup, IonListHeader, IonItem, IonLabel, IonRadio } from '@ionic/vue';
import ModalHeader from '../../components/ModalHeader.vue';
import { defineComponent, ref } from 'vue';
import doAuth from '../../services/auth';
import { useStore } from "vuex";

export default defineComponent({
  name: 'PostOptions',
  props: [],
  components: { IonPage, IonContent, ModalHeader, IonList, IonRadioGroup, IonListHeader, IonItem, IonLabel, IonRadio },
  data() {
    return {
      isAuth: false
    }
  },
  beforeMount: function() {
    this.isAuth = doAuth()
  },
  setup() {
    const profileTypes = ref('everyone');
    const store = useStore();
    return {
      store, profileTypes
    }
  },
  methods: {
    updateProfileTypes: function(val) {
      this.profileTypes = val
    }
  }
});
</script>